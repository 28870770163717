<template>
  <div>
    <vs-card>
      <vs-table ref="table" pagination :max-items="20" search :data="projects">
        <template slot="thead">
          <vs-th>Código</vs-th>
          <vs-th>Descrição</vs-th>
          <vs-th>Valor SAP</vs-th>
          <vs-th>Valor Estimado</vs-th>
          <vs-th>Requisição</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                {{ tr.code }}
              </vs-td>
              <vs-td>
                {{ tr.name }}
              </vs-td>
              <vs-td>
                <span v-if="tr.sapValue"
                  >R$ {{ Number(tr.sapValue).toFixed(2) }} </span
                ><span v-else
                  >R$ {{ Number(tr.externalPurchaseValue).toFixed(2) }}</span
                >
              </vs-td>
              <vs-td> R$ {{ Number(tr.estimatedValue).toFixed(2) }} </vs-td>
              <vs-td>
                <strong v-if="!tr.addedToCart && tr.totalHomologations > 0"
                  ><a href="#" @click="showDetails(tr)">Adicionar</a></strong
                >
                <strong v-if="tr.addedToCart">-</strong>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </vs-table>
      <vs-popup
        :active.sync="showDetailsScreen"
        title="Detalhes da Requisição"
        v-if="itemSelected"
      >
        <h1>{{ itemSelected.description }}</h1>
        <div v-if="errors.count() > 0">
          <fieldset class="fieldErrors">
            <legend>Atenção aos erros do formulário:</legend>
            <ul>
              <li
                v-for="(error, index) in errors.all()"
                :key="index"
                class="alert"
              >
                {{ error }}
              </li>
            </ul>
          </fieldset>
        </div>
        <form>
          Classificação
          <select
            name="classification"
            v-validate="'required'"
            v-model="itemSelected.classificationId"
            label="Classificação"
            class="w-full mb-6 select1"
            data-vv-as="Classificação"
          >
            <option
              :key="index"
              :value="item.id"
              v-for="(item, index) in classificationOptions"
            >
              {{ item.name }}
            </option>
          </select>

          <!--Aplicação
          <select
            v-validate="'required'"
            name="application"
            v-model="itemSelected.applicationId"
            label="Aplicação"
            class="w-full mb-6 select1"
            data-vv-as="Aplicação"
          >
            <option
              :key="index"
              :value="item.id"
              v-for="(item, index) in applicationOptions"
            >
              {{ item.name }}
            </option>
          </select> -->
        </form>
        Impacto
        <select
          v-validate="'required'"
          name="impact"
          v-model="itemSelected.impactId"
          label="Área"
          class="w-full mb-6 select1"
          data-vv-as="Impacto"
        >
          <option
            :key="index"
            :value="item.id"
            v-for="(item, index) in impactOptions"
          >
            {{ item.name }}
          </option>
        </select>
        <p>
          Quantidade:
          <br />
          <vs-input
            type="number"
            v-validate="'required|numeric'"
            name="qtd"
            class="w-full mb-6"
            v-model="itemSelected.qtd"
            data-vv-as="Quantidade"
          ></vs-input>
        </p>
        <p>
          Data da necessidade (mínimo 15 dias):
          <br />
          <vs-input
            type="date"
            v-validate="'required'"
            name="necessityAt"
            class="w-full mb-6"
            v-model="itemSelected.necessityAt"
            data-vv-as="Data de necessidade"
          ></vs-input>
        </p>
        <div>
          <p>Justificativa da sua demanda</p>
          <vs-textarea
            name="justification"
            v-model="itemSelected.justification"
            class="w-full mb-6"
          ></vs-textarea>
        </div>
        <span
          v-if="isAdmin && homologationUnitOptions.length == 0"
          style="color: red"
          >* Não foram localizadas unidades prioritárias para este
          projeto.</span
        >
        <span v-if="isAdmin && homologationUnitOptions.length > 0"
          >Unidade Prioritária</span
        >
        <select
          name="homologationUnit"
          v-model="itemSelected.homologationUnitId"
          label="Unidade Prioritária"
          class="w-full mb-6 select1"
          data-vv-as="Unidade Homologada"
          v-if="isAdmin && homologationUnitOptions.length > 0"
        >
          <option
            :key="index"
            :value="item.unit.id"
            v-for="(item, index) in homologationUnitOptions"
          >
            {{ item.unit.name }}
          </option>
        </select>
        <vs-button @click="addToCart()" class="mr-6"
          >Adicionar ao carrinho</vs-button
        >
      </vs-popup>
    </vs-card>
  </div>
</template>

<script>
import siriusAPI from "../services";
import { notifyError } from "@/utils/helpers";
import { formatDateToLocale } from "@/utils/helpers";
import { getDataInStorage, updateDataInStorage } from "../utils/storage";
import moment from "moment";

export default {
  data() {
    return {
      projects: [],
      showDetailsScreen: false,
      showCartScreen: false,
      cart: [],
      // applicationOptions: [],
      classificationOptions: [],
      impactOptions: [],
      itemSelected: {
        qtd: 1,
        uuid: "",
        classificationId: 0,
        impactId: 0,
        // applicationId: 0,
        justification: "",
        description: "",
        duplicated: false,
        necessityAt: null,
        homologationUnitId: null,
      },
      orders: [],
      homologationUnitOptions: [],
      isAdmin: false,
    };
  },

  created() {
    let loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    const userProfile = loggedUser.profile.name;
    if (userProfile == "Administrador") {
      this.isAdmin = true;
    }
  },

  mounted() {
    this.loadProjects();
    this.loadClassifications();
    // this.loadApplications();
    this.loadImpacts();
    this.loadOrders();
    let storage = getDataInStorage();
    this.cart = storage.cart;
    if (!this.cart) this.cart = [];
  },

  methods: {
    async loadOrders() {
      this.$vs.loading();
      //this.blocks = [];
      await new siriusAPI()
        .getOrders(false, false, false, false, "", "")
        .then((res) => {
          this.orders = res.data;
        })
        .catch(() => {
          this.orders = [];
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },

    loadClassifications() {
      new siriusAPI()
        .getClassifications()
        .then((res) => {
          this.classificationOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de classificação: " + error
          );
        });
    },
    loadHomologationUnits(projectUuid) {
      new siriusAPI()
        .getHomologationUnits(projectUuid)
        .then((res) => {
          this.homologationUnitOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de unidade homologada: " + error
          );
        });
    },

    loadImpacts() {
      new siriusAPI()
        .getImpacts()
        .then((res) => {
          this.impactOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de impacto: " + error
          );
        });
    },

    loadApplications() {
      new siriusAPI()
        .getApplications()
        .then((res) => {
          this.applicationOptions = res.data;
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar as opções de aplicação: " + error
          );
        });
    },
    async showDetails(project) {
      let today_formatted = moment().add(30, "days").format("YYYY-MM-DD");

      this.itemSelected = project;

      await this.loadHomologationUnits(project.uuid);

      if (!this.itemSelected.necessityAt)
        this.itemSelected.necessityAt = today_formatted;

      if (!this.itemSelected.qtd) this.itemSelected.qtd = "1";
      this.showDetailsScreen = true;
      this.itemSelected.classificationId = this.classificationOptions[2].id;
    },

    async findOnOrders() {
      let uuid = this.itemSelected.uuid;
      let qtd = this.itemSelected.qtd;
      this.$vs.loading();
      let duplicatedOrder = null;
      await new siriusAPI()
        .getDuplicity(uuid, qtd)
        .then((res) => {
          duplicatedOrder = res.data;
          this.$vs.loading.close();
        })
        .catch(() => {
          this.$vs.loading.close();
        });
      return duplicatedOrder;
    },
    async addToCart() {
      let has = await this.findOnOrders();
      if (has) {
        this.showDetailsScreen = false;
        this.itemSelected.duplicated = true;
        this.$vs.dialog({
          type: "confirm",
          color: "danger",
          title: `Confirmação`,
          text:
            "Já existe uma requisição em andamento deste projeto para a sua unidade com esta mesma quantidade, solicitado pelo usuário " +
            has.createdUser.name +
            " no dia " +
            formatDateToLocale(has.createdAt) +
            ". Deseja prosseguir mesmo assim?",
          acceptText: "Continuar",
          cancelText: "Cancelar",
          accept: this.acceptAlert,
        });
      } else {
        this.acceptAlert();
      }
    },
    acceptAlert() {
      if (this.validateMinDate(this.itemSelected.necessityAt)) {
        this.cart.push(this.itemSelected);
        this.itemSelected.addedToCart = true;
        this.itemSelected = null;
        this.showDetailsScreen = false;
        updateDataInStorage({ cart: this.cart });
        this.showDetailsScreen = false;
      } else {
        notifyError(this, "Data de necessidade deve ser no mínimo de 15 dias");
      }
    },

    validateMinDate(necessityAt) {
      necessityAt = moment(necessityAt);
      let today = moment();
      let dif = necessityAt.diff(today, "days");
      if (dif < 15) return false;
      return true;
    },

    checkIfIsOnCart(project) {
      let exist = false;
      for (let index = 0; index < this.cart.length; index++) {
        if (this.cart[index].uuid == project.uuid) {
          exist = true;
          index = this.cart.length;
        }
      }
      return exist;
    },

    loadProjects() {
      this.$vs.loading();
      new siriusAPI()
        .getProjects()
        .then((res) => {
          this.projects = res.data;
          this.projects = this.projects.map((project) => ({
            ...project,
            createdDate: formatDateToLocale(project.createdAt),
            addedToCart: this.checkIfIsOnCart(project),
          }));
          this.projects = this.projects.filter(
            (project) => project.homologationUnits.length > 0
          );
        })
        .catch((error) => {
          notifyError(
            this,
            "Erro ao tentar carregar a lista de projetos " + error
          );
        })
        .finally(() => {
          this.$vs.loading.close();
        });
    },
  },
};
</script>
  <style scoped>
.radio-space {
  padding-right: 0.5em;
}
ul {
  margin-bottom: 0.8em;
}
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.alert {
  color: #f00;
}
.fieldErrors {
  padding: 1em;
  margin-bottom: 1em;
  border: 0.8px solid #bbb;
}
</style>
